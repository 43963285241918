<template>
  <v-row no-gutters>
    <v-col>
      <div id="event-list">
        <!-- app drawer -->

        <belge-add-update
          v-model="isAddUpdateEventSidebarActive"
          :event-type="eventType"
          :event-item="eventItem"
          @refetch-data="fetchEvents"
          @upload-image="uploadImage"
          @save-event-event="saveEventEvent"
        ></belge-add-update>

        <event-filter v-model="isFilterEventSidebarActive" @filter-data="filterData"> </event-filter>

        <Image-upload
          v-model="isUploadEventImageActive"
          :data="dataUpload"
          @refetch-data="fetchEvents"
          @save-image-event="saveImageEvent"
        />

        <CoverImage
          v-model="isCoverEventImageActive"
          :data="dataUpload"
          @refetch-data="fetchEvents"
          @save-image-event="saveImageEvent"
        />

        <DocumentUpolad
          v-model="isUploadEventDocumentActive"
          :data="dataUpload1"
          @refetch-data="fetchEvents"
          @save-image-event="saveImageEvent"
        />

        <v-snackbars :objects.sync="snackbars" width="500" top right transition="slide-y-transition">
          <template v-slot:action="{ close }">
            <v-btn icon small @click="close()">
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
        </v-snackbars>

        <v-dialog v-model="dialogDetail" eager max-width="1400">
          <v-card>
            <v-card-title>
              Belge Yönet
              <v-spacer />
              <v-btn color="error" depressed @click="dialogDetail = false">
                Kapat
                <v-icon right>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <input multiple type="file" ref="fileInput" @change="handleFileChange" />

            <v-card-title> </v-card-title>

            <v-card-text class="pa-0">
              <v-data-table
                :headers="detailHeaders"
                :items="detailItems"
                :items-per-page="-1"
                hide-default-footer
                :loading="loading"
              >
                <template #[`item.actions`]="{ item }">
                  <v-tooltip bottom color="error">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        color="error"
                        small
                        plain
                        v-bind="attrs"
                        @click="productImageDelete(item)"
                        v-on="on"
                      >
                        <v-icon medium>
                          {{ icons.mdiDelete }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Belgeyi Sil</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- list filters -->

        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
        >
          <v-toolbar flat :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
            <v-toolbar-title class="text-h6 font-weight-medium">
              <v-icon large left>
                {{ icons.mdiFilePlusOutline }}
              </v-icon>
              Belge Yükle
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn class="text-capitalize ms-3 mr-n4 bg-gradient-primary white--text" tile x-large @click="addEvent()">
              <v-icon dark right class="mr-3">
                {{ icons.mdiPlusThick }}
              </v-icon>
              <span> EKLE</span>
            </v-btn>
          </v-toolbar>

          <!-- table -->
          <v-data-table
            v-model="selectedRows"
            :headers="tableColumns"
            :items="eventListTable"
            :options.sync="options"
            :items-per-page="15"
            fixed-header
            :height="dataTableHeight"
            :server-items-length="totalEventListTable"
            :loading="loading"
          >
            <!-- Text -->

            <template #[`item.is_active`]="{ item }">
              <v-chip :color="item.is_active === '1' ? 'success' : 'warning'" link outlined small>
                {{ item.is_active === '1' ? 'Aktif' : 'Pasif' }}
              </v-chip>
            </template>
            <template #[`item.resim`]="{ item }">
              <v-tooltip v-if="item.resim" right color="white">
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    v-bind="attrs"
                    tile
                    class="rounded d-flex align-center justify-center"
                    :width="!$vuetify.breakpoint.xsOnly ? 90 : 60"
                    :height="!$vuetify.breakpoint.xsOnly ? 90 : 90"
                    :color="item.resim ? 'white' : 'primary'"
                    v-on="on"
                  >
                    <v-img
                      v-if="item.resim"
                      :width="!$vuetify.breakpoint.xsOnly ? 100 : 120"
                      :height="!$vuetify.breakpoint.xsOnly ? 90 : 90"
                      :src="'https://onlineapi.anlas.com/' + item.resim"
                    ></v-img>

                    <v-icon v-else>
                      {{ icons.mdiCameraOff }}
                    </v-icon>
                  </v-card>
                </template>
                <v-img :src="'https://onlineapi.anlas.com/' + item.resim"></v-img>
              </v-tooltip>
              <v-card
                v-else
                tile
                class="rounded d-flex align-center justify-center"
                :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                :color="item.resim ? 'white' : 'primary'"
              >
                <v-img
                  v-if="item.resim"
                  :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                  :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                  :src="'https://onlineapi.anlas.com/' + item.resim"
                ></v-img>
                <v-icon v-else>
                  {{ icons.mdiCameraOff }}
                </v-icon>
              </v-card>

              <!-- <v-card
                tile
                class="rounded d-flex align-center justify-center"
                :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                :color="item.resim ? 'white' : 'primary'"
              >
                <v-img
                  v-if="item.resim"
                  :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                  :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                  :src="'https://onlineapi.anlas.com/' + item.resim"
                ></v-img>
                <v-icon v-else>
                  {{ icons.mdiCameraOff }}
                </v-icon>
              </v-card> -->
            </template>
            <template #[`item.content`]="{ item }">
              <div v-text="item.content"></div>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-btn depressed color="nightDark" small class="text-capitalize text-caption" @click="editEvent(item)">
                Düzenle
              </v-btn>
              <v-tooltip v-if="item.resim" bottom color="warning">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="warning" icon v-bind="attrs" @click="productImageDelete(item)" v-on="on">
                    <v-icon small>
                      {{ icons.mdiCameraOff }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Resimi Kaldır</span>
              </v-tooltip>
              <v-tooltip v-else bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="primary" v-bind="attrs" @click="productImageUpload(item)" v-on="on">
                    <v-icon small>
                      {{ icons.mdiCamera }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Resim Yükle</span>
              </v-tooltip>

              <v-tooltip bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed small plain @click="manageDocument(item)" v-bind="attrs" v-on="on">
                    <v-icon medium color="secondary">
                      {{ icons.mdiPencilBoxOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Belge Yönet</span>
              </v-tooltip>

              <!--

              <v-tooltip bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed small plain @click="productDocumentUpload(item)" v-bind="attrs" v-on="on">
                    <v-icon medium color="secondary">
                      {{ icons.mdiAttachment }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Belge Yükle</span>
              </v-tooltip>
   -->

              <v-tooltip bottom color="error">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed color="error" small plain v-bind="attrs" @click="eventDelete(item)" v-on="on">
                    <v-icon medium>
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Belge Sil</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CoverImage from '@/components/CoverImage.vue'
import DocumentUpolad from '@/components/DocumentUpolad.vue'
import ImageUpload from '@/components/ImageUpload.vue'
import store from '@/store'
import {
  mdiAttachment,
  mdiCamera,
  mdiCameraOff,
  mdiCameraPlusOutline,
  mdiClose,
  mdiDelete,
  mdiEye,
  mdiFilePlusOutline,
  mdiFilter,
  mdiFilterOff,
  mdiPencilBoxOutline,
  mdiPlusThick,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
import eventStoreModule from '../eventStoreModule'
import belgeAddUpdate from './BelgeAddUpdate.vue'
import eventAddUpdate from './EventAddUpdate.vue'
import eventFilter from './EventFilter.vue'

export default {
  components: {
    eventAddUpdate,
    belgeAddUpdate,
    eventFilter,
    ImageUpload,
    VSnackbars,
    CoverImage,
    DocumentUpolad,
    DocumentUpolad,
  },
  setup() {
    const EVENT_APP_STORE_MODULE_NAME = 'app-event'
    const snackbars = ref([])

    // this won't work because we are passing a number to watch()

    // Register module
    if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(EVENT_APP_STORE_MODULE_NAME, eventStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(EVENT_APP_STORE_MODULE_NAME)
      }
    })

    const isAddUpdateEventSidebarActive = ref(false)
    const isFilterNumber = ref(0)
    const isFilterEventSidebarActive = ref(false)
    const isUploadEventImageActive = ref(false)
    const isCoverEventImageActive = ref(false)
    const isUploadEventDocumentActive = ref(false)
    const dataUpload = ref({})
    const dataUpload1 = ref({})
    const eventListTable = ref([])
    const eventType = ref('add')
    const dialogDetail = ref(false)
    const eventItem = ref({})
    const detailItems = ref([])

    const productDocumentUpload = item => {
      dataUpload1.value = item
      isUploadEventDocumentActive.value = true
    }

    const productImageUpload = item => {
      dataUpload.value = item
      isCoverEventImageActive.value = true
    }

    const manageDocument = item => {
      dialogDetail.value = true
      fetchDetail(item)
      dataUpload.value = item
    }
    const fetchDetail = item => {
      const method = 'getDownloadsDetail'
      const id = item.id
      store.dispatch('postMethod', { method, id }).then(response => {
        console.log(response)
        detailItems.value = response.detail.files

        loading.value = false
        //pageLoading.value = false
      })
    }

    const handleFileChange = event => {
      console.log(dataUpload.value)
      for (var i = 0; i < event.target.files.length; i++) {
        loading.value = true
        const formData = new FormData()
        formData.append('method', 'addMedia')
        formData.append('realfilename', event.target.files[i].name)
        formData.append('modul', 'downloads')
        formData.append('seq', 1)
        formData.append('file', event.target.files[i])

        formData.append('localid', dataUpload.value.id),
          formData.append(
            'sizes',
            JSON.stringify({
              bresim: 900,
              kresim: 350,
            }),
          )

        store.dispatch('uploadMethod', formData).then(response => {
          loading.value = true
          fetchDetail(dataUpload.value)

          if (response.error === 0) {
            console.log(event.target.files[i])
            close()
            emit('refetch-data')
            emit('save-image-event', {
              message: 'Ürününe Resim Eklendi',
              color: 'info',
              timeout: 5000,
            })
          } else {
            loading.value = false
          }
        })
      }
    }

    const tableColumns = [
      {
        text: 'Resim',
        value: 'resim',
        width: '100px',
        height: '100px',
        sortable: false,
      },
      {
        text: 'Durum',
        value: 'is_active',
        width: '90px',
      },

      {
        text: 'Başlık',
        value: 'stext',
        width: '200px',
      },

      {
        text: 'İçerik',
        value: 'ltext',
      },

      {
        width: '400px',
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const detailHeaders = ref([
      {
        text: 'Dosya Adı',
        value: 'gdosya_adi',
        width: '360',
        sortable: false,
      },
      {
        width: '400px',
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ])

    const searchQuery = ref('')
    const urunKodFilter = ref(null)
    const idFilter = ref(null)
    const statusFilter = ref(null)
    const baslikFilter = ref(null)
    const customerFilter = ref(null)
    const totalEventListTable = ref(0)
    const customerGroupOptions = ref([])
    const pricelistOptions = ref([])
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const eventTotalLocal = ref([])
    const selectedRows = ref([])

    const eventImageUpload = item => {
      dataUpload.value = item
      isUploadEventImageActive.value = true
    }
    const uploadImage = item => {
      dataUpload.value = item
      isUploadEventImageActive.value = true
    }
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]

    const eventDelete = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Duyurusu silme işlemini onaylayın',
        icon: 'question',
        background: '#FF4C51',
        showCloseButton: false,
        showCancelButton: true,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FF4C51',
        confirmButtonColor: '#cc3d41',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'deleteDownloads',
              id: item.id,
            })
            .then(() => {
              snackbars.value.push({
                message: `${item.baslik} Duyurusu Silindi`,
                color: 'error',
                timeout: 5000,
              })

              fetchEvents()
            })
            .catch(e => {
              Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            })
        }
      })
    }

    const saveEventEvent = val => {
      snackbars.value.push(val)
    }
    const saveImageEvent = val => {
      snackbars.value.push(val)
    }

    const editEvent = item => {
      eventItem.value = item
      eventType.value = 'edit'
      isAddUpdateEventSidebarActive.value = true
    }
    const addEvent = () => {
      eventItem.value = {}
      eventType.value = 'add'
      isAddUpdateEventSidebarActive.value = true
    }
    const filterData = item => {
      idFilter.value = item.id || null
      statusFilter.value = item.aktif || null
      urunKodFilter.value = item.urun_kodu || null
      baslikFilter.value = item.baslik || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i
    }

    const productImageDelete = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Resimi silme işlemini onaylayın',
        icon: 'question',
        background: '#FFB400',
        showCancelButton: true,
        showCloseButton: false,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FFB400',
        confirmButtonColor: '#cc9000',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'removeMediaItem',
              uploadid: item.uploadid,
            })
            .then(() => {
              snackbars.value.push({
                message: `${item.baslik} Ürünün Resmi Silindi`,
                color: 'error',
                timeout: 3000,
              })

              fetchEvents()
              fetchDetail(dataUpload.value)
            })
            .catch(e => {
              Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            })
        }
      })
    }

    const fetchEvents = () => {
      const query = {
        options: options.value,
        id: idFilter.value,
        aktif: statusFilter.value,
        urun_kodu: urunKodFilter.value,
        baslik: baslikFilter.value,
        customer: customerFilter.value,
      }
      const method = 'getDownloads'
      store
        .dispatch('fetchMethod', { query, method })
        .then(response => {
          console.log(response)
          eventListTable.value = response.response.result
          response.response.result.forEach(item => {
            console.log(item.ltext)
            item.ltext = item.ltext.replace(/\\'/g, "'").replace(/\\"/g, '"')
            item.stext = item.stext.replace(/\\'/g, "'").replace(/\\"/g, '"')
          })

          totalEventListTable.value = Number(response.response.pagination.totalRec)
          loading.value = false
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    watch([searchQuery, idFilter, urunKodFilter, statusFilter, baslikFilter, customerFilter, options], () => {
      loading.value = true
      selectedRows.value = []
      fetchEvents()
    })

    return {
      statusFind,
      eventItem,
      editEvent,
      addEvent,
      eventType,
      productImageDelete,
      isFilterNumber,
      filterData,
      eventListTable,
      tableColumns,
      searchQuery,
      urunKodFilter,
      baslikFilter,
      manageDocument,
      idFilter,
      statusFilter,
      fetchDetail,
      detailItems,
      productImageUpload,
      productDocumentUpload,
      totalEventListTable,
      handleFileChange,
      loading,
      options,
      eventTotalLocal,
      isAddUpdateEventSidebarActive,
      isFilterEventSidebarActive,
      isUploadEventImageActive,
      isCoverEventImageActive,
      isUploadEventDocumentActive,
      selectedRows,
      fetchEvents,
      eventDelete,
      snackbars,
      eventImageUpload,
      dataUpload,
      dialogDetail,
      detailHeaders,
      dataUpload1,
      uploadImage,
      saveEventEvent,
      saveImageEvent,
      customerFilter,
      customerGroupOptions,
      pricelistOptions,

      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiPencilBoxOutline,
        mdiCameraOff,
        mdiCameraPlusOutline,
        mdiAttachment,
        mdiFilePlusOutline,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
